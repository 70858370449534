* {
  /* border: solid 1px red; */
  box-sizing: border-box;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: #ccc;
  font-display: swap;
}

html {
  height: 100%;
  width: 100vw;
  overflow-x: hidden;
}

#root {
  height: 100%;
}

#root::-webkit-scrollbar {
  display: none;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-display: swap;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  font-display: swap;
}

p {
  margin: 0px; /* To work-around bizarre margin-collapsing. */
}
